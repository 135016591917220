//Colors
$springGreen: #95E1BF;
$forestGreen: #21362C;
$purple: #4D2A98;
$burntOrange: #B44A06;
$silverGray: #6F7271;
$cloudyGray: #DFE1DF;
$sunsetOrange: #FFA621;
$blue: #3479bd;
$green: #40a251;
$orange: #D1B464;
$lightGray: #f2f2f2;
$red: $burntOrange;
$dashboardBlack: #26303F;
$buttonColor: $purple;
$buttonTextColor: white;
$textColor: $forestGreen;
$borderRadius: 0rem;

$purpleFade: lighten($purple, 60%);
$redFade: rgba(255, 0, 0, 0.05);
$greenFade: lighten($green, 50%);

//Box-shadow
@mixin buttonShadow {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
}

@mixin modalShadow {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
//Texts

//Media queries and mobile adjustments
@mixin phone {
    @media (max-width: 800px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 801px) {
        @content; 
    }
}

@mixin ingress {
    margin: 0 0 2.4rem 0;
    display: flex;
    font-size: 1.6rem;
    max-width: 60rem;
}

@mixin keyValueList {
    .keyValueList {
        li {
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 0.1rem solid $cloudyGray;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.sub {
                font-size: 1.4rem;
                color: $silverGray;
            }
            &.sumTotal {
                font-weight: 700;
                color: $forestGreen;
            }
            .key {}
            .value {
                text-align: right;
                margin-left: 1rem;
            }
        }
    }
}

@mixin flexRowHistoryRight {
    .flexRowHistoryRight {
        display: flex;
        flex-direction: column;
        
        @media (min-width: 1250px) {
            flex-direction: row;
            > div:nth-child(2) {
                margin-left: 2rem;
            }
        }
    }
}

@mixin errorMessage {
    .fieldErrorMessage {
        font-size: 1.4rem;
        color: $red;
        background-color: lighten($red, 55);
        background-color: $redFade;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        border-left: 4px solid $red;
        margin-top: 1.2rem;
        color: #21362c;
    }
}

@mixin backLink {
    .backLink {
        cursor: pointer;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        @media (max-width: 600px) {
            margin-left: 2rem;
        }
        i {
            font-size: 2rem;
            margin-right: 0.4rem;
        }
        span {
            font-weight: 600;
        }
    }
}