@import "./variables.scss";

html {
    //background-color: #f2f2f2;
    font-weight: 800;
    font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;
  color: #222;
  @include phone {
    font-size: 1.4rem;
  }
}

* {
    box-sizing: border-box;
}

.flex {
    display: flex;
}

i + span {
    margin-left: 0.4rem;
}

a {
    color: $purple;
}

h1 {
    font-weight: 800;
}

h1, h2, h3, h4 {
    color: $forestGreen;
}

figure {
    margin-left: 0;
    margin-right: 0;
}

footer {
    font-size: 1.2rem;
    color: $silverGray;
    position: fixed;
    bottom: 0;
    background-color: #f9f9f9;
    box-sizing: border-box;
    border-top: 0.1rem solid $cloudyGray;
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    font-weight: 600;
    @include phone {
        display: none;
    }
    @media (min-width: 900px) {
        width: calc(100% - 30rem);
        margin-left: 30rem;
        padding-left: 12rem;
        padding-right: 4rem;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 75rem;
        //border-top: 0.1rem solid $cloudyGray;
    }
}

.last-edited {
    color: $silverGray;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    margin-top: -1rem;
    margin-bottom: 2rem;
    span {
        font-weight: 500;
    }
}

.logo-container {
    width: 14rem;
    display: inline-flex;
    @media (max-width: 900px) {
        width: 12rem;
    }
}

.search-option {
    display: flex;
    flex-direction: column;
    mark {
        background-color: $purpleFade;
        color: $purple;
        font-weight: 600;
    }
    .option-title {
        font-weight: 700;
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
        color: $forestGreen;
    }
    .option-description {
        font-size: 1.2rem;
        color: $silverGray;
    }
}

.movie-iframe {
    width: 80rem;
    height: 45rem;
    max-width: 100%;
}

.breadcrumbs {
    display: flex;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    li {
        list-style: none;
        margin-right: 1rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: $forestGreen;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        @include phone {
            font-size: 1.2rem;
        }
        a {
            color: $purple;
            i {
                text-decoration: none;
            }
        }
        &.noDecorate {
            a {
                text-decoration: none;
            }
        }
        &::before {
            content: ">";
            margin-right: 1rem;
        }
        &:first-child {
            &::before {
                content: "";
                margin-right: 0;
            }
        }
    }
}

img {
    max-width: 100%;
}

.medium-zoom-image--opened {
    left: calc(50% + 30rem)!important;
    top: 50%!important;
    max-width: calc(100% - 400px)!important;
    height: auto!important;
    transform: translate(calc(-50% - 15rem), -50%)!important;
}

main {
    max-width: 80rem;
}

.notion-text {
    margin-bottom: 1rem;
}

.notion-h2 {
    margin-bottom: 0.8rem;
    font-size: 2.2rem;
    margin-top: 3rem;
}

.notion-h3 {
    font-size: 2rem;
    margin-bottom: 0.4rem;
}

.notion-hash-link {
    display: none;
}

.notion-page-link {
    text-decoration: none;
    .notion-page-title {
        display: flex;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 0.1rem solid $cloudyGray;
        font-weight: 600;
        max-width: 60rem;
        .notion-page-icon-inline {
            display: none;
        }
        b {
            font-weight: inherit;
        }
    }
}

.hero {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    @include phone {
        flex-direction: column;
    }
    h1 {
        font-size: 5rem;
        max-width: 45rem;
        margin-top: 0;
        @include phone {
            font-size: 3.6rem;
        }
    }
    img {
        max-width: 33rem;
        @include phone {
            max-width: 100%;
            width: 20rem;
            margin-right: auto;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        font-weight: 600;
        color: $silverGray;
        flex-wrap: wrap;
        @include phone {
            flex-direction: column;
        }
        li {
            display: flex;
            align-items: center;
            margin-right: 2rem;
            margin-bottom: 1rem;
        }
    }
}

.card-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        margin-right: 2rem;
        margin-bottom: 2rem;
        max-width: 100%;
    }
}

.fixrate-card-list-item {
    width: 40rem;
    max-width: 100%;
    box-sizing: border-box;
    button {
        font-family: 'Montserrat', sans-serif;
        justify-content: flex-start;
    }
    h3 {
        margin-top: 0;
        margin-bottom: 0;
    }
    p {
        margin-top: 0.6rem;
        margin-bottom: 0;
        color: $silverGray;
        font-weight: 500;
    }
    i {
        font-size: 3rem;
        margin-left: 2rem;
        color: $purple;
    }
}

@include phone {
    h1 {
        font-size: 2.6rem;
    }
    .notion-h2 {
        font-size: 2rem;
    }

    .notion-h3 {
        font-size: 1.6rem;
    }
}